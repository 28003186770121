import { navigate, useRoutes } from "hookrouter";

import { Layout, Menu } from "antd";
import { ConsoleSqlOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { NotFoundPage } from "./pages/NotFound";

import "antd/dist/antd.css";
import { routes, TAGS, tags } from "./routes";
import { useState } from "react";

const App = () => {
  const routeResult = useRoutes(routes);
  const [selectedMenu, setSelectedMenu] = useState();

  let content = <NotFoundPage />;
  if (routeResult !== null) {
    content = routeResult.page();
  }

  return (
    <Layout style={{ backgroundColor: "white" }}>
      <Layout.Header style={{ padding: 0 }}>
        <h1 style={{ float: "left", margin: "auto 20px" }}>数据仓库</h1>
        <Menu mode="horizontal" defaultSelectedKeys={[selectedMenu]}>
        {/*  <Menu.Item*/}
        {/*    key={TAGS.QUERY}*/}
        {/*    icon={<ConsoleSqlOutlined />}*/}
        {/*    onClick={() => {*/}
        {/*      setSelectedMenu(TAGS.QUERY);*/}
        {/*      navigate(tags[TAGS.QUERY]);*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    查询*/}
        {/*  </Menu.Item>*/}
        {/*  <Menu.Item*/}
        {/*    key={TAGS.CREATE}*/}
        {/*    icon={<PlusSquareOutlined />}*/}
        {/*    onClick={() => {*/}
        {/*      setSelectedMenu(TAGS.CREATE);*/}
        {/*      navigate(tags[TAGS.CREATE]);*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    建表*/}
        {/*  </Menu.Item>*/}
        </Menu>
      </Layout.Header>
      <Layout.Content>{content}</Layout.Content>
      <Layout.Footer style={{ textAlign: "center" }}>
        QuantUrban ©{new Date().getFullYear()}
      </Layout.Footer>
    </Layout>
  );
};

export default App;
