const invertByCallback = (obj, cb) => {
  const result = {};
  const keys = Object.keys(obj);
  for (let i = 0, length = keys.length; i < length; i++) {
    result[cb(obj[keys[i]])] = keys[i];
  }
  return result;
};

export default invertByCallback;
