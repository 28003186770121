import { useMemo, useState } from "react";
import { Button, Divider, Space } from "antd";

import Editor from "../components/Editor";
import FetchMessage from "../components/FetchMessage";
import FetchPreview from "../components/FetchPreview";

import useOnce from "../hooks/useOnce";
import useFetch from "../hooks/useFetch";

const useValidate = (code) => {
  const [once, setOnce] = useOnce();

  const parsedCode = useMemo(() => {
    let parsedCode = null;
    try {
      parsedCode = JSON.parse(code);
    } catch (e) {}
    return parsedCode;
  }, [code]);

  const [result, loading] = useFetch(
    "schema/validate/",
    "POST",
    JSON.stringify({ schema: parsedCode }),
    !!parsedCode && once
  );

  return [setOnce, loading, result];
};

const useCreate = (code) => {
  const [once, setOnce] = useOnce();

  const parsedCode = useMemo(() => {
    let parsedCode = null;
    try {
      parsedCode = JSON.parse(code);
    } catch (e) {}
    return parsedCode;
  }, [code]);

  const [result, loading, clearResult] = useFetch(
    "schema/create/",
    "POST",
    JSON.stringify({ schema: parsedCode }),
    !!parsedCode && once
  );

  return [setOnce, loading, result, clearResult];
};

export const CreatePage = () => {
  const [code, setCode] = useState("");
  const [setValidateOnce, validateLoading, validateResult] = useValidate(code);
  const [
    setCreateOnce,
    createLoading,
    createResult,
    clearCreateResult,
  ] = useCreate(code);

  const reformat = () => {
    try {
      const parsedCode = JSON.parse(code);
      setCode(JSON.stringify(parsedCode, null, 2));
    } catch (_) {}
  };

  return (
    <div style={{ marginTop: 8 }}>
      <Editor
        code={code}
        setCode={setCode}
        width="100%"
        height="400px"
        mode="json"
      />
      {createResult === null ? (
        <>
          <FetchMessage resp={validateResult} />
          <FetchPreview resp={validateResult} />
        </>
      ) : (
        <>
          <FetchMessage resp={createResult} />
        </>
      )}

      <Divider />
      <Space style={{ margin: "8px" }}>
        <Button
          type="primary"
          disabled={!(validateResult && validateResult.status === 200)}
          onClick={setCreateOnce}
          loading={createLoading}
        >
          建表
        </Button>
        <Button
          onClick={() => {
            setValidateOnce();
            clearCreateResult();
          }}
          loading={validateLoading}
        >
          校验
        </Button>
        <Button onClick={reformat}>格式化</Button>
      </Space>
    </div>
  );
};
