import CodeMirrorReact from "@uiw/react-codemirror";
import "codemirror/keymap/sublime";
import "codemirror/theme/neo.css";
import "codemirror/addon/display/placeholder.js";
import "codemirror/addon/hint/sql-hint.js";
import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/hint/show-hint.css";
import CodeMirror from "codemirror";

const Editor = ({
  code,
  setCode,
  width,
  height,
  mode,
  readOnly = false,
  tables = {},
}) => {
  const onChange = (editor) => {
    setCode && setCode(editor.getValue());
  };

  return (
    <CodeMirrorReact
      width={width}
      height={height}
      value={code}
      options={{
        theme: "neo",
        keyMap: "sublime",
        mode,
        readOnly,
        fullScreen: true,
        placeholder: `在此处输入 ${mode} 代码...`,

        extraKeys: { Tab: "autocomplete" },
        hint: CodeMirror.hint.sql,
        hintOptions: { tables },
      }}
      onChange={onChange}
    />
  );
};

export default Editor;
