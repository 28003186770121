import { useCallback, useEffect, useState } from "react";
import { navigate } from "hookrouter";
import { TAGS, tags } from "../routes";

export const HOST = process.env.REACT_APP_URL || "/";
export const API_HOST = HOST + "rest/";

const useFetch = (path, method, body = null, fire = true) => {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!fire) {
      return;
    }

    const params = {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("TOKEN"),
      },
      body,
    };

    setLoading(true);
    let status = null;
    fetch(API_HOST + path, params)
      .then((resp) => {
        setLoading(false);
        status = resp.status;
        return resp.json();
      })
      .then((data) => {
        setResult({ status, data: data });
      })
      .catch(function (err) {
        setLoading(false);
        setResult({ status, data: { message: "Something bad happened." } });
      })
      .finally(() => {
        if (status === 401) {
          navigate(tags[TAGS.LOGIN], true);
        }
      });
  }, [path, method, body, fire]);

  const clearResult = useCallback(() => {
    setResult(null);
  }, [setResult]);

  return [result, loading, clearResult];
};

export default useFetch;
