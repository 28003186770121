import { QueryPage } from "./pages/Query";
import { NotFoundPage } from "./pages/NotFound";
import invertByCallback from "./utils/invertByCallback";
import { CreatePage } from "./pages/Create";
import { LoginPage } from "./pages/Login";

export const TAGS = {
  QUERY: "query",
  CREATE: "create",
  LOGIN: "login",
  NOT_FOUND: "404",
};

export const routes = {
  // "/": () => ({
  //   tag: TAGS.QUERY,
  //   page: () => <QueryPage />,
  // }),
  "/": () => ({
    tag: TAGS.CREATE,
    page: () => <CreatePage />,
  }),
  "/login": () => ({
    tag: TAGS.LOGIN,
    page: () => <LoginPage />,
  }),
  "/404": () => ({
    tag: TAGS.NOT_FOUND,
    page: () => <NotFoundPage />,
  }),
};

export const tags = invertByCallback(routes, (i) => i()["tag"]);
