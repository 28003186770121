const FetchPreview = ({ resp }) => {
  if (resp === null || resp.data === null || !resp.data.detail) return null;

  return (
    <div style={{ margin: "8px" }}>
      <h3>结果预览</h3>
      <pre style={{ whiteSpace: "pre-wrap" }}>{resp.data.detail}</pre>
    </div>
  );
};

export default FetchPreview;
