import { Alert, Typography } from "antd";

const FetchMessage = ({ resp }) => {
  if (resp === null) return null;

  let type = "error";
  if (resp.status === 200) {
    type = "success";
  } else if (resp.status === 400) {
    type = "warning";
  }

  return (
    <Alert
      type={type}
      message={
        <Typography.Text style={{ whiteSpace: "pre" }}>
          {resp.data.message}
        </Typography.Text>
      }
      showIcon
    />
  );
};

export default FetchMessage;
