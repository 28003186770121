import { Button, Col, Form, Input, Row } from "antd";
import { useState } from "react";

export const LoginPage = () => {
  const [token, setToken] = useState("");
  return (
    <div style={{ marginTop: 16 }}>
      <Row gutter={16}>
        <Col span={12} offset={6}>
          <Form>
            <Form.Item>
              <Input
                placeholder="在此输入登录 Token..."
                value={token}
                onChange={(e) => {
                  setToken(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                onClick={() => {
                  localStorage.setItem("TOKEN", token);
                  window.location.href = "/";
                }}
              >
                登录
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};
