import { useEffect, useState } from "react";

const useOnce = () => {
  const [once, setOnce] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOnce(false);
    }, 0);
  }, [once]);

  const setOnceTrue = () => setOnce(true);

  return [once, setOnceTrue];
};

export default useOnce;
